import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import VisorStyle from '../../css/Visor.module.css';

export default function MenuClaroUniverse(props) {
    const { viewersClaroUniverse, setActiveMenuButton, handleShowClaroUniverse, paths, handleButtonClick, activeMenuButton, location, dataViewers } = props;
    console.log(paths)
    useEffect(() => {
        if (viewersClaroUniverse.length > 0) {
            if (activeMenuButton === '') {
                setActiveMenuButton(viewersClaroUniverse[0].id)
                localStorage.setItem("selectedMenuButton", viewersClaroUniverse[0].id)
            }

            const condition = (value) => (location.pathname).split('/')[2] === value.label.toLowerCase().split(" ").join("-");

            if ((location.pathname).includes('personas')) {
                const index = dataViewers.findIndex(condition);
                if (dataViewers[index]) {
                    if (dataViewers[index].tipo_visor === 1) {
                        const index = (dataViewers.findIndex(condition)) - viewersClaroUniverse.length / 2;
                        if (index >= 0) {
                            setActiveMenuButton(dataViewers[index].id)
                            localStorage.setItem("selectedMenuButton", dataViewers[index].id)
                        }
                    }
                }
            } else if (dataViewers.length > 0) {
                const index = dataViewers.findIndex(condition);
                setActiveMenuButton(dataViewers[index].id)
                localStorage.setItem("selectedMenuButton", dataViewers[index].id)
            }
        }
    }, [viewersClaroUniverse, location.pathname, activeMenuButton]);

    const months = viewersClaroUniverse.filter(visor => visor.label.includes('Hogares')).map((viewer, i) => {
        const condition = (value) => value.split('/')[2] === viewer.label.toLowerCase().split(" ").join("-");
        const index = paths.slice(2).findIndex(condition);
        const isActive = activeMenuButton === viewer.id;
        return (
            <Link key={i}
                to={paths[index + 2]}
                className={`${VisorStyle["btn-month"]}${isActive ? ` ${VisorStyle["active"]}` : ''}`}
                onClick={() => handleButtonClick(viewer.id, index)}>
                <ion-icon name="calendar-clear-outline"></ion-icon>
                <span>{viewer.label.replace(" Hogares", "")}</span>
            </Link>
        );
    });

    const removeMenuItems = () => {
        let elementos = [...document.getElementsByClassName(VisorStyle["btn-month"])];

        // Recorre los elementos y verifica si contienen la palabra "Personas" en un elemento <p>
        elementos.forEach(function (elemento) {
            let parrafo = elemento.querySelector('p');
            if (parrafo) {
                if (parrafo.textContent.includes('Personas')) {
                    // Si el elemento contiene la palabra "Personas" en un elemento <p>, elimínalo
                    elemento.style.display = 'none';
                } else if (parrafo.textContent.includes('Hogares')) {
                    // Si el elemento contiene la palabra "Hogares" en un elemento <p>,
                    // reemplaza el contenido del párrafo eliminando la palabra
                    parrafo.textContent = parrafo.textContent.replace(' Hogares', '');
                }
            }
        });
    }

    useEffect(() => {
        removeMenuItems();
    }, [viewersClaroUniverse]);

    return (
        <>
            <div className={VisorStyle["btn-month"]} onClick={handleShowClaroUniverse}>
                <ion-icon name="arrow-back-outline" />
                <span>Regresar</span>
            </div>
            {months}
        </>
    );
}