import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";

import MenuClaroUniverse from "./MenuClaroUniverse";
import MenuCincoMinutos from "./MenuCincoMinutos";
import MenuOtros from "./MenuOtros";

import VisorStyle from '../../css/Visor.module.css';

export default function MenuNuevoUniverso(props) {
    const { viewersNewUniverse, viewersClaroUniverse, viewersFiveMinutes, othersViewers, paths, changeViewer, location, dataViewers } = props;
    const [activeMenuButton, setActiveMenuButton] = useState(Number(localStorage.getItem("selectedMenuButton")) || '');
    const [showClaroUniverse, setShowClaroUniverse] = useState(false);
    const [showFiveMinutes, setShowFiveMinutes] = useState(false);
    const [showOthersViewers, setShowOthersViewers] = useState(false);

    useEffect(() => {
        if (viewersNewUniverse.length > 0) {
            if (activeMenuButton === '') {
                setActiveMenuButton(viewersNewUniverse[0].id)
                localStorage.setItem("selectedMenuButton", viewersNewUniverse[0].id)
            }

            const condition = (value) => (location.pathname).split('/')[2] === value.label.toLowerCase().split(" ").join("-");

            if ((location.pathname).includes('personas')) {
                const index = dataViewers.findIndex(condition);
                if (dataViewers[index]) {
                    if (dataViewers[index].tipo_visor === 3) {
                        const index = (dataViewers.findIndex(condition)) - viewersNewUniverse.length / 2;
                        if (index >= 0) {
                            setActiveMenuButton(dataViewers[index].id)
                            localStorage.setItem("selectedMenuButton", dataViewers[index].id)
                        }
                    }
                }
            } else if (dataViewers.length > 0) {
                const index = dataViewers.findIndex(condition);
                setActiveMenuButton(dataViewers[index].id)
                localStorage.setItem("selectedMenuButton", dataViewers[index].id)
            }
        }
    }, [viewersNewUniverse, location.pathname, activeMenuButton]);

    const handleButtonClick = useCallback((id, index) => {
        setActiveMenuButton(id);
        localStorage.setItem("selectedMenuButton", id)
        changeViewer(index);
        localStorage.setItem("selectedButton", 'hogares')
    }, [changeViewer]);

    const months = viewersNewUniverse.filter(visor => visor.label.includes('Hogares')).map((viewer, index) => {
        const isActive = activeMenuButton === viewer.id;
        return (
            <Link key={index}
                to={paths[index + 2]}
                className={`${VisorStyle["btn-month"]}${isActive ? ` ${VisorStyle["active"]}` : ''}`}
                onClick={() => handleButtonClick(viewer.id, index)}>
                <ion-icon name="calendar-clear-outline"></ion-icon>
                <span>{viewer.label.replace(" Hogares", "")}</span>
            </Link>
        );
    });

    const handleShowClaroUniverse = () => {
        setShowClaroUniverse(!showClaroUniverse);
    };

    const handleShowFiveMinutes = () => {
        setShowFiveMinutes(!showFiveMinutes);
    };

    const handleShowOthersViewers = () => {
        setShowOthersViewers(!showOthersViewers);
    };

    const removeMenuItems = () => {
        let elementos = [...document.getElementsByClassName(VisorStyle["btn-month"])];

        // Recorre los elementos y verifica si contienen la palabra "Personas" en un elemento <p>
        elementos.forEach(function (elemento) {
            let parrafo = elemento.querySelector('p');
            if (parrafo) {
                if (parrafo.textContent.includes('Personas')) {
                    // Si el elemento contiene la palabra "Personas" en un elemento <p>, elimínalo
                    elemento.style.display = 'none';
                } else if (parrafo.textContent.includes('Hogares')) {
                    // Si el elemento contiene la palabra "Hogares" en un elemento <p>,
                    // reemplaza el contenido del párrafo eliminando la palabra
                    parrafo.textContent = parrafo.textContent.replace(' Hogares', '');
                }
            }
        });
    }

    useEffect(() => {
        removeMenuItems();
    }, [viewersNewUniverse]);

    return (
        <div className={VisorStyle["menu-calendar"]}>
            {(!showClaroUniverse && !showFiveMinutes && !showOthersViewers) && (
                <>
                    {months}
                    {viewersClaroUniverse.length > 0 && (
                        <div className={VisorStyle["btn-month"]} onClick={handleShowClaroUniverse}>
                            <ion-icon name="chevron-forward-circle-outline" />
                            <span>Visores Universo Claro</span>
                        </div>
                    )}
                    {viewersFiveMinutes.length > 0 && (
                        <div className={VisorStyle["btn-month"]} onClick={handleShowFiveMinutes}>
                            <ion-icon name="chevron-forward-circle-outline" />
                            <span>Visores 5 Min</span>
                        </div>
                    )}
                    {othersViewers.length > 0 && (
                        <div className={VisorStyle["btn-month"]} onClick={handleShowOthersViewers}>
                            <ion-icon name="chevron-forward-circle-outline" />
                            <span>Otros Visores</span>
                        </div>
                    )}
                </>
            )}

            {showClaroUniverse && (
                <MenuClaroUniverse
                    viewersClaroUniverse={viewersClaroUniverse}
                    setActiveMenuButton={setActiveMenuButton}
                    handleShowClaroUniverse={handleShowClaroUniverse}
                    paths={paths}
                    location={location}
                    dataViewers={dataViewers}
                    handleButtonClick={handleButtonClick}
                    activeMenuButton={activeMenuButton}
                />
            )}

            {showFiveMinutes && (
                <MenuCincoMinutos
                    viewersFiveMinutes={viewersFiveMinutes}
                    handleShowFiveMinutes={handleShowFiveMinutes}
                    paths={paths}
                    changeViewer={changeViewer}
                    handleButtonClick={handleButtonClick}
                    activeMenuButton={activeMenuButton}
                />
            )}

            {showOthersViewers && (
                <MenuOtros
                    othersViewers={othersViewers}
                    handleShowOthersViewers={handleShowOthersViewers}
                    paths={paths}
                    changeViewer={changeViewer}
                    handleButtonClick={handleButtonClick}
                    activeMenuButton={activeMenuButton}
                />
            )}
        </div>
    );
}