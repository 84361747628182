import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import ScopeStyle from '../css/Scope.module.css'

import { API_DEFAULT } from "../services/settings";

function Scope(props) {

    const { setAccessToken, accessToken, getAccessTokenWithRefresh, setShowLoader, setShowAlert, setMessage, setSeverity } = props

    // Obtener el elemento drop-zone y el elemento input de archivo
    let dropZone = document.getElementById('drop-zone');
    let fileInput = document.getElementById('file-input');
    let fileName = document.getElementById('file-name');

    // Función para abrir el cuadro de diálogo de selección de archivo
    function openFileInput() {
        fileInput.click();
    }

    // Agregar listeners de eventos para el arrastre y la caída
    if (dropZone != null) {
        dropZone.addEventListener('dragover', handleDragOver, false);
        dropZone.addEventListener('drop', handleFileSelect, false);
    }

    // Función para evitar que el navegador abra el archivo al soltarlo
    function handleDragOver(evt) {
        evt.stopPropagation();
        evt.preventDefault();
        evt.dataTransfer.dropEffect = 'copy';
    }

    // Función para manejar la selección de archivos
    function handleFileSelect(evt) {
        evt.stopPropagation();
        evt.preventDefault();

        // Obtener la lista de archivos que se están cargando
        let files = evt.dataTransfer ? evt.dataTransfer.files : evt.target.files;

        // Validar que se esté cargando un archivo xlsx
        let validExtensions = [".xlsx"];
        let fileNameLabel = [];

        let fileExtension = files[0].name.split('.').pop();
        if (validExtensions.indexOf("." + fileExtension) !== -1) {
            fileNameLabel.push(files[0].name);
        } else {
            setShowLoader(false);
            setMessage("El formato de archivo no es válido.");
            setSeverity("danger");
            setTimeout(() => { setShowAlert(true) }, 0);
            fileInput.value = "";
        }

        // Mostrar el nombre del archivo debajo del input
        if (fileNameLabel.length > 0) {
            fileName.innerHTML = fileNameLabel;
        }

    }

    const dOWList = [
        { value: 0, label: "Lunes" },
        { value: 1, label: "Martes" },
        { value: 2, label: "Miércoles" },
        { value: 3, label: "Jueves" },
        { value: 4, label: "Viernes" },
        { value: 5, label: "Sábado" },
        { value: 6, label: "Domingo" }
    ];

    const poblationList = [
        { value: "accounts", label: "Hogares" },
        { value: "people", label: "Personas" },
        { value: "both", label: "Ambos" }
    ];

    const [evaluationOption, setEvaluationOption] = useState('post')
    const [universeOption, setUniverseOption] = useState('new')
    const [lowerPreDate, setLowerPreDate] = useState();
    const [upperPreDate, setUpperPreDate] = useState();

    const [selectedOption, setSelectedOption] = useState('evaluacion-pauta')
    const [lowerDate, setLowerDate] = useState();
    const [upperDate, setUpperDate] = useState();
    const [dOWOptions, setDOWOptions] = useState(dOWList);
    const [canalProgramaOptions, setCanalProgramaOptions] = useState([]);
    // const [unificadosOptions, setUnificadosOptions] = unificados;
    const [poblationOptions, setPoblationOptions] = useState();

    const [dataCanalPrograma, setDataCanalPrograma] = useState([])
    const [unificadosOptions, setUnificadosOptions] = useState(false)

    const [dataRangeDates, setDataRangeDates] = useState({})

    // Función que obtiene un objeto[] con la informaciónd de las tuplas canal y programa para reach neto
    const getFechas = async (access) => {
        const res = await fetch(
            `${API_DEFAULT}/usuarios/range-dates`, {
            headers: {
                "Authorization": "Bearer " + access
            }
        }
        )

        res.json().then(
            async data => {
                if (data['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);
                    }
                }
                else {
                    setDataRangeDates(data)
                }
            }
        )
    }

    // Función que obtiene un objeto[] con la informaciónd de las tuplas canal y programa para reach neto
    const getCanalPrograma = async (access, loader, unificados = unificadosOptions) => {
        if (loader) { setShowLoader(true) };
        const res = await fetch(
            unificados ? `${API_DEFAULT}/usuarios/canal-programa-alter` : `${API_DEFAULT}/usuarios/canal-programa`, {
            headers: {
                "Authorization": "Bearer " + access
            }
        }
        )

        res.json().then(
            async data => {
                if (data['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);
                    }
                }
                else {
                    setDataCanalPrograma(data['canal_name'].map((v, i) => {
                        return ({ value: i, label: v })
                    }));
                    if (loader) { setShowLoader(false) };
                }
            }
        )
    }

    const getCanalProgramaClean = async (access = accessToken) => {
        setShowLoader(true)
        const res = await fetch(
            `${API_DEFAULT}/usuarios/canal-programa-clean`, {
            headers: {
                "Authorization": "Bearer " + access
            }
        }
        )

        res.json().then(
            async data => {
                if (data['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        getCanalProgramaClean(newAccess)
                    }
                }
                else {
                    let csvContent = "data:text/csv;charset=utf-8,"
                    csvContent += Object.keys(data[0]).join(",") + "\n"

                    data.forEach(row => {
                        csvContent += '"' + Object.values(row).join('","') + '"' + "\n"
                    });

                    let element = document.createElement('a');
                    element.setAttribute('href', encodeURI(csvContent));
                    element.setAttribute('download', "listado_names.csv");
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);

                    setShowLoader(false)
                }
            }
        )
    }

    // Obtiene las tuplas canal y programa para la vista de reach neto
    useEffect(() => {
        if (document.getElementById('scope').className === 'Scope_scope__4vn1a Scope_show-scope__kD-JU') { getCanalPrograma(accessToken, true) } else { getCanalPrograma(accessToken, false) }
    }, [accessToken, unificadosOptions]);

    // Obtiene las tuplas canal y programa para la vista de reach neto
    useEffect(() => {
        getFechas(accessToken)
    }, [accessToken, unificadosOptions]);

    const hideScope = () => {
        setEvaluationOption('post')
        setUniverseOption('new')
        setLowerPreDate()
        setUpperPreDate()

        setSelectedOption('evaluacion-pauta')
        setLowerDate()
        setUpperDate()
        setDOWOptions(dOWList)
        setCanalProgramaOptions([])
        setUnificadosOptions(false)
        setPoblationOptions()
        const scope = document.getElementById('scope')
        scope.classList.remove(ScopeStyle["hide-scope"])
        scope.classList.toggle(ScopeStyle["show-scope"])

        document.getElementById("email").value = "";
        document.getElementById("file-input").value = "";
        document.getElementById("file-name").innerHTML = "";
    }


    const generateReach = async (access = accessToken) => {
        setShowLoader(true)

        let body = new FormData();
        body.append("email", document.getElementById("email").value)

        if (body.get('email') === '') {
            setShowLoader(false);
            setMessage("El email no puede estar vacío");
            setSeverity("danger");
            setTimeout(() => { setShowAlert(true) }, 0);
        }
        else if (canalProgramaOptions.length === 0) {
            setShowLoader(false);
            setMessage("El programa no puede estar vacío");
            setSeverity("danger");
            setTimeout(() => { setShowAlert(true) }, 0);
        }
        else if (lowerDate === undefined || lowerDate === '') {
            setShowLoader(false);
            setMessage("La fecha inicial no puede estar vacío");
            setSeverity("danger");
            setTimeout(() => { setShowAlert(true) }, 0);
        }
        else if (upperDate === undefined || upperDate === '') {
            setShowLoader(false);
            setMessage("La fecha final no puede estar vacío");
            setSeverity("danger");
            setTimeout(() => { setShowAlert(true) }, 0);
        }
        else if (dOWOptions.length === 0) {
            setShowLoader(false);
            setMessage("Los días de la semana no pueden estar vacíos");
            setSeverity("danger");
            setTimeout(() => { setShowAlert(true) }, 0);
        }
        else if (poblationOptions === undefined || poblationOptions === null) {
            setShowLoader(false);
            setMessage("La población no puede estar vacía");
            setSeverity("danger");
            setTimeout(() => { setShowAlert(true) }, 0);
        }
        else if (body.get('email') !== '') {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (regex.test(body.get('email')) === false) {
                setShowLoader(false);
                setMessage("Ha ingresado un correo inválido");
                setSeverity("danger");
                setTimeout(() => { setShowAlert(true) }, 0);
            }
            else if (lowerDate > upperDate) {
                setShowLoader(false);
                setMessage("La fecha inicial debe ser menor o igual a la final");
                setSeverity("danger");
                setTimeout(() => { setShowAlert(true) }, 0);
            }
            else {
                body.append("canal_name", canalProgramaOptions.map((v) => { return (v["label"]) }).join(";;"))
                body.append("lower_date", lowerDate)
                body.append("upper_date", upperDate)
                body.append("days_of_week", dOWOptions.map((v) => { return (v["value"]) }).join(" "))
                body.append("together", unificadosOptions ? "True" : "False")
                body.append("group", poblationOptions["value"])

                const res = await fetch(
                    `${API_DEFAULT}/usuarios/reach-neto`, {
                    method: 'POST',
                    headers: {
                        "Authorization": "Bearer " + access
                    },
                    body: body
                }
                )

                res.json().then(
                    async data => {
                        // console.log(data)
                        if (data['code'] === 'token_not_valid') {
                            let newAccess = await getAccessTokenWithRefresh();
                            if (newAccess) {
                                setAccessToken(newAccess);
                                localStorage.setItem('access', newAccess);

                                generateReach(newAccess);
                            }
                        }
                        else if (data === 'Consult has been created') {
                            setShowLoader(false);
                            hideScope()
                            setMessage("La consulta ha sido creada. Pronto al correo " + document.getElementById("email").value + " recibirá el reach neto generado.");
                            setSeverity("success");
                            setTimeout(() => { setShowAlert(true) }, 0);
                        }
                        else {
                            setShowLoader(false);
                            setMessage("Error");
                            setSeverity("danger");
                            setTimeout(() => { setShowAlert(true) }, 0);
                        }
                    }
                )
            }
        }
    }


    const generateScope = async (access = accessToken) => {
        setShowLoader(true)

        let body = new FormData();
        body.append("email", document.getElementById("email").value)
        body.append("template", document.getElementById("file-input").files[0]);
        body.append("type", evaluationOption)

        if (universeOption === "new") {
            body.append("universe", 1)
        }

        if (evaluationOption === "pre") {
            body.append("lower_date", lowerPreDate)
            body.append("upper_date", upperPreDate)
        }

        if (body.get('email') === '') {
            setShowLoader(false);
            setMessage("El email no puede estar vacío");
            setSeverity("danger");
            setTimeout(() => { setShowAlert(true) }, 0);
        }
        else if (body.get('template') === 'undefined') {
            setShowLoader(false);
            setMessage("No se ha seleccionado ningún archivo");
            setSeverity("danger");
            setTimeout(() => { setShowAlert(true) }, 0);
        }
        if (evaluationOption === "pre") {
            body.append("lower_date", lowerPreDate)
            body.append("upper_date", upperPreDate)

            if (lowerPreDate === undefined || lowerPreDate === '') {
                setShowLoader(false);
                setMessage("La fecha inicial no puede estar vacío");
                setSeverity("danger");
                setTimeout(() => { setShowAlert(true) }, 0);
            }
            else if (upperPreDate === undefined || upperPreDate === '') {
                setShowLoader(false);
                setMessage("La fecha final no puede estar vacío");
                setSeverity("danger");
                setTimeout(() => { setShowAlert(true) }, 0);
            }
        }
        if (body.get('email') !== '') {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (regex.test(body.get('email')) === false) {
                setShowLoader(false);
                setMessage("Ha ingresado un correo inválido");
                setSeverity("danger");
                setTimeout(() => { setShowAlert(true) }, 0);
            }
            else if (lowerPreDate > upperPreDate) {
                setShowLoader(false);
                setMessage("La fecha inicial debe ser menor o igual a la final");
                setSeverity("danger");
                setTimeout(() => { setShowAlert(true) }, 0);
            }
            else {
                const res = await fetch(
                    `${API_DEFAULT}/usuarios/scopes`, {
                    method: 'POST',
                    headers: {
                        "Authorization": "Bearer " + access
                    },
                    body: body
                }
                )

                res.json().then(
                    async data => {
                        // console.log(data)
                        if (data['code'] === 'token_not_valid') {
                            let newAccess = await getAccessTokenWithRefresh();
                            if (newAccess) {
                                setAccessToken(newAccess);
                                localStorage.setItem('access', newAccess);

                                generateScope(newAccess);
                            }
                        }
                        else if (data['template']) {
                            if (evaluationOption == "post") {
                                if (data['template'][0] === 'The file has no valid format, use the template.') {
                                    setShowLoader(false);
                                    setMessage("La información del archivo cargado no es válida. Usa el template");
                                    setSeverity("danger");
                                    setTimeout(() => { setShowAlert(true) }, 0);
                                }
                                else if (data['template'][0] === 'The file has no valid spots.') {
                                    setShowLoader(false);
                                    setMessage("El archivo no tiene spots. Revisa en intenta nuevamente.");
                                    setSeverity("danger");
                                    setTimeout(() => { setShowAlert(true) }, 0);
                                }
                                else if (data['template']['fecha']) {
                                    if (data['template']['fecha'][0] === 'The field has an incorrect format.') {
                                        setShowLoader(false);
                                        setMessage("El formato de fecha es incorrecto. Revisa e intenta nuevamente.");
                                        setSeverity("danger");
                                        setTimeout(() => { setShowAlert(true) }, 0);
                                    }
                                }
                                else if (data['template']['hora']) {
                                    if (data['template']['hora'][0] === 'The field has an incorrect format.') {
                                        setShowLoader(false);
                                        setMessage("El formato de hora es incorrecto. Revisa e intenta nuevamente.");
                                        setSeverity("danger");
                                        setTimeout(() => { setShowAlert(true) }, 0);
                                    }
                                }
                                else if (data['template']['duracion']) {
                                    if (data['template']['duracion'][0] === 'The field has an incorrect format.') {
                                        setShowLoader(false);
                                        setMessage("El formato de la duración es incorrecto. Revisa e intenta nuevamente.");
                                        setSeverity("danger");
                                        setTimeout(() => { setShowAlert(true) }, 0);
                                    }
                                }
                                else if (data['template']['nombre_canal']) {
                                    if (data['template']['nombre_canal'][0] === 'The field has names that does not exist in the channel list.') {
                                        setShowLoader(false);
                                        setMessage("Ha ingresado un nombre de canal incorrecto. Revisa e intenta nuevamente.");
                                        setSeverity("danger");
                                        setTimeout(() => { setShowAlert(true) }, 0);
                                    }
                                }
                            }
                            else {
                                /* MAPEAR LOS MENSAJES DEL BACK */
                                if (data['template'][0] === 'The file has no valid format, use the template.') {
                                    setShowLoader(false);
                                    setMessage("La información del archivo cargado no es válida. Usa el template");
                                    setSeverity("danger");
                                    setTimeout(() => { setShowAlert(true) }, 0);
                                }
                                else if (data['template'][0] === 'The file has no valid spots.') {
                                    setShowLoader(false);
                                    setMessage("El archivo no tiene spots. Revisa en intenta nuevamente.");
                                    setSeverity("danger");
                                    setTimeout(() => { setShowAlert(true) }, 0);
                                }
                                else if (data['template']['nombre_canal']) {
                                    if (data['template']['nombre_canal'][0] === 'The field has names that does not exist in the channel list.') {
                                        setShowLoader(false);
                                        setMessage("Ha ingresado un nombre de canal incorrecto. Revisa e intenta nuevamente.");
                                        setSeverity("danger");
                                        setTimeout(() => { setShowAlert(true) }, 0);
                                    }
                                }
                                else if (data['template']['dia_semana']) {
                                    if (data['template']['dia_semana'][0] === 'The field value does not exist among the options.') {
                                        setShowLoader(false);
                                        setMessage("Ha ingresado un día de la semana incorrecto. Revisa e intenta nuevamente.");
                                        setSeverity("danger");
                                        setTimeout(() => { setShowAlert(true) }, 0);
                                    }
                                }
                                else if (data['template']['name']) {
                                    if (data['template']['name'][0] === 'The field has names that does not exist in the program list.') {
                                        setShowLoader(false);
                                        setMessage("Ha ingresado un nombre de programa incorrecto. Revisa el archivo 'error_names.csv'.");
                                        setSeverity("danger-scope");
                                        setTimeout(() => { setShowAlert(true) }, 0);

                                        let csvContent = "data:text/csv;charset=utf-8,"
                                        csvContent += Object.keys(data['template']['name'][1][0]).join(",") + "\n"

                                        data['template']['name'][1].forEach(row => {
                                            csvContent += '"' + Object.values(row).join('","') + '"' + "\n"
                                        });

                                        let element = document.createElement('a');
                                        element.setAttribute('href', encodeURI(csvContent));
                                        element.setAttribute('download', "error_names.csv");
                                        document.body.appendChild(element);
                                        element.click();
                                        document.body.removeChild(element);
                                    }
                                }
                                else if (data['template']['duracion']) {
                                    if (data['template']['duracion'][0] === 'The field has an incorrect format.') {
                                        setShowLoader(false);
                                        setMessage("El formato de la duración es incorrecto. Revisa e intenta nuevamente.");
                                        setSeverity("danger");
                                        setTimeout(() => { setShowAlert(true) }, 0);
                                    }
                                }
                                else if (data['template']['n_spots']) {
                                    if (data['template']['n_spots'][0] === 'The field has an incorrect format.') {
                                        setShowLoader(false);
                                        setMessage("El formato de la cantidad de spots es incorrecto. Revisa e intenta nuevamente.");
                                        setSeverity("danger");
                                        setTimeout(() => { setShowAlert(true) }, 0);
                                    }
                                }
                            }
                        }
                        else if (data === 'File has been uploaded') {
                            setShowLoader(false);
                            hideScope()
                            setMessage("El archivo ha sido cargado. Pronto al correo " + document.getElementById("email").value + " recibirá el alcance generado.");
                            setSeverity("success");
                            setTimeout(() => { setShowAlert(true) }, 0);
                        }
                    }
                )
            }
        }

    }

    const downloadTemplate = () => {
        let element = document.createElement('a');
        if (evaluationOption === "post") {
            element.setAttribute('href', "https://storage.googleapis.com/bucket-back-ratings/reportes/CNC/template_alcances.xlsx")
            element.setAttribute('download', "template_alcances.xlsx");
        }
        else {
            element.setAttribute('href', "https://storage.googleapis.com/bucket-back-ratings/reportes/CNC/template_alcances_pre.xlsx")
            element.setAttribute('download', "template_alcances_pre.xlsx");
        }
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    return (
        <>
            <div className={ScopeStyle["scope"]} id="scope">
                <div className={ScopeStyle["layer-hide-menu"]} onClick={() => hideScope()}></div>
                <div className={ScopeStyle["scope-container"]} id="scope-container">
                    <div className={ScopeStyle["scope-header"]}>
                        <h2>Generador de consultas</h2>
                    </div>
                    <div className={ScopeStyle["scope-container-scroll"]}>
                        <form id="contact-form">
                            <label for="email">Email</label>
                            <input type="email" id="email" name="email" placeholder="ejemplo@dominio.com" required />
                            <div style={{ width: "94%", margin: "auto", marginBottom: "8px" }}>
                                <label style={{ marginLeft: "0px" }}>
                                    <input type="radio" value="evaluacion-pauta" checked={selectedOption === "evaluacion-pauta"} onChange={(e) => setSelectedOption(e.target.value)} />
                                    &ensp;Evaluación de pauta&emsp;&ensp;
                                </label>
                                <label style={{ marginLeft: "0px" }}>
                                    <input type="radio" value="reach-neto" checked={selectedOption === "reach-neto"} onChange={(e) => setSelectedOption(e.target.value)} />
                                    &ensp;Reach neto
                                </label>
                            </div>
                            <div style={{ borderBottom: "1px solid #cccccc", width: "100%", width: "94%", margin: "auto", marginTop: "6px", marginBottom: "14px" }} />
                            {selectedOption === "evaluacion-pauta" ?
                                <>
                                    <div style={{ width: "94%", margin: "auto", marginBottom: "8px" }}>
                                        <label style={{ marginLeft: "0px" }}>
                                            <input type="radio" value="pre" checked={evaluationOption === "pre"} onChange={(e) => setEvaluationOption(e.target.value)} />
                                            &ensp;Pre evaluación&emsp;&ensp;
                                        </label>
                                        <label style={{ marginLeft: "0px" }}>
                                            <input type="radio" value="post" checked={evaluationOption === "post"} onChange={(e) => setEvaluationOption(e.target.value)} />
                                            &ensp;Post evaluación
                                        </label>
                                    </div>
                                    {evaluationOption === "pre" ?
                                        <>
                                            <div style={{ width: "94%", margin: "auto", marginTop: "10px" }} />
                                            <label>Rango fechas</label>
                                            <div style={{ display: "flex", width: "94%", margin: "auto", marginBottom: "12px", alignItems: "center" }}>
                                                <span style={{ marginLeft: "2px", marginRight: "10px" }}>Del</span><input type="date" min={new Date(new Date().setDate(new Date().getDate() - 366)).toJSON().slice(0, 10)} max={new Date(new Date().setDate(new Date().getDate() - 1)).toJSON().slice(0, 10)} value={lowerPreDate} onChange={(e) => setLowerPreDate(e.target.value)} /><span style={{ marginLeft: "10px", marginRight: "10px" }}>al</span><input type="date" min={new Date(dataRangeDates["min_fecha"]).toJSON().slice(0, 10)} max={new Date(dataRangeDates["max_fecha"]).toJSON().slice(0, 10)} value={upperPreDate} onChange={(e) => setUpperPreDate(e.target.value)} />
                                            </div></>
                                        : null}
                                    <div style={{ borderBottom: "1px solid #cccccc", width: "100%", width: "94%", margin: "auto", marginTop: "6px", marginBottom: "14px" }} />
                                    <div style={{ width: "94%", margin: "auto", marginBottom: "8px" }}>
                                        <label style={{ marginLeft: "0px" }}>
                                            <input type="radio" value="new" checked={universeOption === "new"} onChange={(e) => setUniverseOption(e.target.value)} />
                                            &ensp;Nuevo Universo&emsp;&ensp;
                                        </label>
                                        <label style={{ marginLeft: "0px" }}>
                                            <input type="radio" value="claro" checked={universeOption === "claro"} onChange={(e) => setUniverseOption(e.target.value)} />
                                            &ensp;Universo Claro
                                        </label>
                                    </div>
                                    <div style={{ width: "94%", margin: "auto", marginTop: "10px" }} />
                                    <label for="file-input">Archivo</label><div className={ScopeStyle["drag-and-drop"]}>
                                        <div id="drop-zone" className={ScopeStyle["drop-zone"]} onClick={() => openFileInput()}>
                                            Arrastra y suelta o haz clic aquí para seleccionar un archivo.
                                            <input type="file" id="file-input" className={ScopeStyle["scope-file-input"]} name="file-input" onChange={(e) => handleFileSelect(e)} />
                                        </div>
                                        <p id="file-name"></p>
                                    </div></>
                                :
                                null}
                        </form>
                    </div>

                    {selectedOption === 'reach-neto' ?
                        <>
                            <div style={{ display: "flex", marginTop: "2px", marginBottom: "-5px" }}>
                                <label>Programas unificados</label>
                                <input type="checkbox" id="toggle" checked={unificadosOptions} onChange={(e) => { setUnificadosOptions(!unificadosOptions); setCanalProgramaOptions([]) }} />
                                <label style={{ marginLeft: "10px", marginBottom: "0px" }} for="toggle" role="switch"></label>
                            </div>
                            <div style={{ width: "94%", margin: "auto", marginTop: "10px", marginBottom: "12px" }} />
                            <label>Canal y Programa</label>
                            <div style={{ width: "94%", margin: "auto", marginTop: "10px", marginBottom: "12px" }}>
                                <Select
                                    options={dataCanalPrograma}
                                    placeholder="Seleccione canal y programa"
                                    isSearchable={true}
                                    /*isClearable={true}*/
                                    isMulti
                                    isOptionDisabled={() => canalProgramaOptions.length >= 3}
                                    value={canalProgramaOptions}
                                    onChange={(e) => setCanalProgramaOptions(e)}
                                />
                            </div>
                            <label>Rango fechas</label>
                            <div style={{ display: "flex", width: "94%", margin: "auto", marginTop: "10px", marginBottom: "12px", alignItems: "center" }}>
                                <span style={{ marginLeft: "2px", marginRight: "10px" }}>Del</span><input type="date" min={new Date(dataRangeDates["min_fecha"]).toJSON().slice(0, 10)} max={new Date(dataRangeDates["max_fecha"]).toJSON().slice(0, 10)} value={lowerDate} onChange={(e) => setLowerDate(e.target.value)} /><span style={{ marginLeft: "10px", marginRight: "10px" }}>al</span><input type="date" min={new Date(dataRangeDates["min_fecha"]).toJSON().slice(0, 10)} max={new Date(dataRangeDates["max_fecha"]).toJSON().slice(0, 10)} value={upperDate} onChange={(e) => setUpperDate(e.target.value)} />
                            </div>
                            <label>Días de la semana</label>
                            <div style={{ width: "94%", margin: "auto", marginTop: "10px", marginBottom: "12px" }}>
                                <Select
                                    options={dOWList}
                                    placeholder="Seleccione los días a consultar"
                                    isSearchable={true}
                                    isMulti
                                    value={dOWOptions}
                                    onChange={(e) => setDOWOptions(e)}
                                />
                            </div>
                            <label>Población</label>
                            <div style={{ width: "94%", margin: "auto", marginTop: "10px", marginBottom: "12px" }}>
                                <Select
                                    options={poblationList}
                                    placeholder="Seleccione población objetivo"
                                    isSearchable={true}
                                    isClearable={true}
                                    value={poblationOptions}
                                    onChange={(e) => setPoblationOptions(e)}
                                />
                            </div>
                        </>
                        :
                        null}
                    <div className={ScopeStyle["scope-button-container"]}>
                        {selectedOption === "evaluacion-pauta" & evaluationOption === "pre" ? <button style={{ right: '60%' }} className={ScopeStyle["scope-button-cancel"]} onClick={() => hideScope()}>CANCELAR</button> : null}
                        {selectedOption === "evaluacion-pauta" ? (evaluationOption === "post" ? <button className={ScopeStyle["scope-button-cancel"]} onClick={() => hideScope()}>CANCELAR</button> : <button className={ScopeStyle["scope-button-download-2"]} onClick={() => getCanalProgramaClean()}>LISTA PROGRAMAS</button>) : null}
                        {selectedOption === "evaluacion-pauta" ? <button id="btnTemplate" className={ScopeStyle["scope-button-template"]} onClick={() => downloadTemplate()}>{evaluationOption === "pre" ? "TEMPLATE PRE" : "TEMPLATE POST"}</button> : <button className={ScopeStyle["scope-button-cancel"]} style={{ right: "20%" }} onClick={() => hideScope()}>CANCELAR</button>}
                        <button id="btnDownload" className={ScopeStyle["scope-button-download"]} onClick={() => selectedOption === "evaluacion-pauta" ? generateScope() : generateReach()}>GENERAR</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Scope;